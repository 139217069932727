import { isEmptyObject, isObject } from '../../libraries/app.library';
import { validSubscriberFields } from '../../config/constants.config';

export const identify = (updateData: any) => {
  if (!updateData) {
    throw new Error(
      'Payload is required. The payload should be in object format, e.g., {key: value}.',
    );
  }

  if (!isObject(updateData)) {
    throw new Error('Payload must be an object.');
  }

  if (isEmptyObject(updateData)) {
    throw new Error('Payload must have at least one key.');
  }

  // Validate keys
  for (const key in updateData) {
    if (typeof key !== 'string') {
      throw new Error('Key must be a string.');
    }

    if (!validSubscriberFields.includes(key)) {
      let message = '';

      validSubscriberFields.forEach((validKey, index) => {
        if (index === validSubscriberFields.length - 1) {
          message = `${message}, and ${validKey}`;
        } else {
          message = message ? `${message}, ${validKey}` : validKey;
        }
      });

      throw new Error(`Key ${key} is not valid. Valid keys are ${message}.`);
    }
  }

  // Validate values
  for (const key in updateData) {
    const value = updateData[key];

    if (typeof value !== 'string' && typeof value !== 'number' && typeof value !== 'boolean') {
      throw new Error('Value must be a string, number, or boolean.');
    }
  }
};

export const logout = (subscriberFieldNames: string[]) => {
  if (!subscriberFieldNames) {
    throw new Error('Subscriber field names are required.');
  }

  if (!Array.isArray(subscriberFieldNames)) {
    throw new Error('Subscriber field names must be an array.');
  }

  for (const fieldName of subscriberFieldNames) {
    if (typeof fieldName !== 'string') {
      throw new Error('Subscriber field name must be a string.');
    }

    if (!validSubscriberFields.includes(fieldName)) {
      let message = '';

      validSubscriberFields.forEach((validKey, index) => {
        if (index === validSubscriberFields.length - 1) {
          message = `${message}, and ${validKey}`;
        } else {
          message = message ? `${message}, ${validKey}` : validKey;
        }
      });

      throw new Error(
        `Subscriber field name ${fieldName} is not valid. Valid names are ${message}.`,
      );
    }
  }
};
