import { getBrowserInfo } from '../libraries/browser.library';
import { subscribeToPushNotificationFromWorker } from '../utils/subscription.util';
import log from '../libraries/log.library';
import { safeParse, isObject, omit, noop } from '../libraries/app.library';
import { getAppData, getGeoInfo } from '../api/app.api';
import { addSubscriberDataToSite } from '../api/subscriber.api';
import {
  optInTypes as OPT_IN_TYPES,
  defaultNotificationTag,
  defaultNotificationTitle,
  workerMessengerCommand,
} from '../config/constants.config';
import AppException from '../exceptions/App.exception';
import {
  addFailedClickedNotificationTag,
  addFailedViewedNotificationTag,
  deleteFailedClickedNotificationTag,
  deleteFailedViewedNotificationTag,
  getFailedClickedNotificationTags,
  getFailedViewedNotificationTags,
  getNotificationTagsByKey,
  getAppId as getAppIdFromDb,
  updateNotificationTagByKey,
} from '../utils/indexedDB.util';
import { removeSubscriberIdFromTag } from '../utils/app.util';
import {
  sendNotificationViewedAnalytics,
  sendNotificationClickedAnalytics,
  fetchPayloadLessNotifications,
  sendNotificationViewedReferAnalytics,
  fetchPayloadNotifications,
} from '../api/notification.api';
import { sendErrorLog } from '../api/errorLog.api';
import { getValueFromUrlByKey } from '../libraries/url.library';

const shouldFetchPushPayload = (notificationOptions: TRawNotificationOptions): boolean => {
  if (notificationOptions.customData && notificationOptions.customData.refetch) {
    return true;
  }

  return false;
};

const getFirstClientUrl = (): Promise<string> => {
  return (self as unknown as { clients: Clients }).clients
    .matchAll({
      includeUncontrolled: true,
      type: 'window',
    })
    .then((clientList: any) => {
      if (clientList.length === 0) {
        return '';
      }

      return clientList[0].url;
    });
};

/**
 * NOTE: Retrieve the app ID by first reading from the URL, then checking the
 * database, and finally falling back to the global variable.
 */
export const getAppId = async (): Promise<string | null> => {
  /**
   * Retrieve the app id from the URL. During the registration of the service
   * worker, the app id is added to the service worker path before
   * registration.
   */
  let appId = getValueFromUrlByKey('appId', location.href) || null;

  if (appId) {
    return appId;
  }

  /**
   * Retrieve the app id from the database. When a user visits the site,
   * we synchronize the app id to the database.
   */
  try {
    appId = await getAppIdFromDb();
  } catch (e) {
    appId = null;
  }

  if (appId) {
    return appId;
  }

  /**
   * Retrieve the app id from the global variable. This variable is available
   * when accessing the service worker through a subdomain.
   */
  appId = typeof PUSHENGAGE_APP_ID !== 'undefined' ? PUSHENGAGE_APP_ID : null;

  return appId;
};

/**
 * NOTE: This function wil never reject.
 */
export const processFailedAnalytics = async () => {
  try {
    // Process failed viewed analytics
    const failedViewedNotificationTags = await getFailedViewedNotificationTags();

    for (let i = 0; i < failedViewedNotificationTags.length; i++) {
      const { id: tag } = failedViewedNotificationTags[i];

      await sendNotificationViewedAnalytics({
        tag,
      });

      // Delete data from db no need to keep it
      await deleteFailedViewedNotificationTag(tag);
    }

    // Process failed clicked analytics
    const failedClickedNotificationTags = await getFailedClickedNotificationTags();

    for (let i = 0; i < failedClickedNotificationTags.length; i++) {
      const { id: tag, action } = failedClickedNotificationTags[i];

      await sendNotificationClickedAnalytics({
        tag,
        action,
      });

      // Delete data from db no need to keep it
      await deleteFailedClickedNotificationTag(tag);
    }
  } catch (error: any) {
    log.error(`Error during process failed analytics, ${error.message}`);
  }
};

export const checkAndUpdateNotificationInDbByKey = async (
  notificationTag: string,
  key: 'viewedNotificationTags' | 'clickedNotificationTags',
): Promise<boolean> => {
  const isTagExist = false;

  try {
    const notificationTags = await getNotificationTagsByKey(key);

    if (notificationTags.length) {
      const notificationTagWithoutSubscriberId = removeSubscriberIdFromTag(notificationTag);

      const isTagExistInDb = notificationTags.some(
        tag => notificationTagWithoutSubscriberId === removeSubscriberIdFromTag(tag),
      );

      if (isTagExistInDb) {
        return true;
      }
    }

    await updateNotificationTagByKey(notificationTag, key);
  } catch (error: any) {
    log.error(`Error while checking and updating notification tag in db, ${error.message}`);
  }

  return isTagExist;
};

export const broadcastReply = async (
  command: TAMPMessengerCommand,
  payload: TAMPMessengerPayload,
) => {
  const clientList = await (self as unknown as ServiceWorkerGlobalScope).clients.matchAll();

  for (let i = 0; i < clientList.length; i++) {
    clientList[i].postMessage({ command, payload });
  }
};

export const getNotificationUrlAndUserAction = (
  data: TNotificationOptions['data'] | string,
  action?: string,
) => {
  let userAction: 'action1' | 'action2' | 'action3' = 'action3';
  let notificationUrl = isObject(data)
    ? (<TNotificationOptions['data']>data)?.url
    : (data as string);

  let parsedAction = null;

  if (action) {
    parsedAction = safeParse<{ action: string; action_url: string }>(action);
  }

  if (parsedAction?.action == 'action1') {
    userAction = 'action1';
    notificationUrl = parsedAction.action_url || notificationUrl;
  } else if (parsedAction?.action == 'action2') {
    userAction = 'action2';
    notificationUrl = parsedAction.action_url || notificationUrl;
  }

  return { userAction, notificationUrl: notificationUrl || '/' };
};

export const isValidPushPayload = (payload: any): boolean => {
  if (
    payload &&
    Array.isArray(payload) &&
    payload.length &&
    payload[0].options &&
    payload[0].options.tag
  ) {
    return true;
  }

  return false;
};

export const parseOrFetchNotifications = async (
  event: PushEvent,
  deviceToken: string,
): Promise<TRawNotification[]> => {
  /**
   * Retrieve the payload-less notifications. Although payload-less
   * notifications are not supported by browsers, we still utilize them for
   * advertising purposes.
   */
  if (!event?.data) {
    const notifications = await fetchPayloadLessNotifications(deviceToken);

    return notifications;
  }

  // Parse the payload push notifications
  let payload;

  try {
    payload = event.data.json();
  } catch (error: any) {
    throw new AppException({
      message: `Parsing of the push payload failed, ${event.data.text()}.`,
      name: 'PushPayloadParseError',
      details: { worker: self.location.href },
    });
  }

  if (!isValidPushPayload(payload)) {
    throw new AppException({
      message: 'Valid JSON but not PushEngage payload.',
      name: 'NotPushEngagePayload',
      details: { payload, worker: self.location.href },
    });
  }

  const notification: TRawNotification = payload[0];

  if (!notification.options?.tag || !shouldFetchPushPayload(notification.options)) {
    return [notification];
  }

  /**
   * Retrieve the payload notifications. This method is used for fetching
   * payload notifications, which can be utilized for ads or to reduce server
   * costs associated with heavy payloads.
   */
  const notifications = await fetchPayloadNotifications({
    tag: notification.options.tag,
    postback: notification.options?.customData?.postback || '',
  });

  return notifications;
};

export const formatNotifications = (notifications: TRawNotification[]): TNotification[] => {
  /**
   * Tags are a combination of 'identifier-site_id-notification_id-subscriber_id.'
   * If a user has multiple subscriptions, they will receive multiple notifications.
   * To handle this, we are removing the 'subscriber_id' from the last part of the
   * tag, making it the same for a particular notification.
   *
   * Why are there multiple subscriptions?
   *
   * This occurs when the service worker path changes, creating a new
   * subscription. We are not unsubscribing from existing subscriptions, as
   * we cannot determine whether a subscription was created through PushEngage
   * or not. After discussion, we have planned to fix this issue from the
   * service worker's end.
   *
   * Additionally, we are adding logic to prevent the sending of duplicate
   * viewed and clicked analytics.
   */
  return notifications.map(notification => {
    const { tag, data, actions } = notification.options || {};

    const tagToString = tag ? tag.toString() : '';

    // If the notification actions icon is null, then it should be removed.
    const formattedActions: TNotificationAction[] = [];

    if (actions && Array.isArray(actions)) {
      actions.forEach(function (action) {
        if (!action.icon) {
          formattedActions.push(omit(action, ['icon']) as TNotificationAction);
        }
      });
    }

    return {
      ...notification,
      options: {
        ...notification.options,
        tag: removeSubscriberIdFromTag(tagToString),
        actions: formattedActions.length ? formattedActions : undefined,
        data: {
          originalTag: tagToString,
          url: data || '',
        },
      },
    };
  });
};

const handleNotificationView = async ({
  notificationOptions,
  subscription,
}: {
  notificationOptions: TNotificationOptions;
  subscription: TWebPushPermissionData;
}) => {
  /**
   * We are processing the failed analytics based on the last notification
   * received. While this processing is also handled in the SDK, when a
   * subscription occurs on a subdomain, both the main domain and subdomain
   * create their own IndexedDB instances, which cannot share data with each
   * other. As a result, we need to manage the processing here as well. This
   * approach aids both sites, whether they collect subscribers on the main
   * domain or a subdomain.
   */
  await processFailedAnalytics();

  if (!notificationOptions?.tag) {
    return;
  }

  const tag = notificationOptions?.data?.originalTag || notificationOptions?.tag;

  const isAlreadyNotificationViewed = await checkAndUpdateNotificationInDbByKey(
    tag,
    'viewedNotificationTags',
  );

  if (isAlreadyNotificationViewed) {
    return;
  }

  try {
    await sendNotificationViewedAnalytics({
      tag,
    });

    log.info('Response from viewed analytics');
  } catch (error: any) {
    log.info(`Failed response from viewed analytics, ${error.name}`);

    // Other errors are processed by the server through error logging.
    if (navigator.onLine === false) {
      // No need to throw error in this case
      try {
        await addFailedViewedNotificationTag({ tag });
      } catch (e: any) {
        log.error(`Failed to add failed viewed notification tag to db, ${e.message}`);
      }
    } else {
      // This error is handled by the backend, so the payload should be added.
      sendErrorLog('service-worker', error, {
        tag,
        name: 'viewCountTrackingFailed',
        endpoint: subscription.endpoint,
        data: {
          tag,
        },
      });
    }
  }
};

export const showNotification = async ({
  title,
  subscription,
  notificationOptions,
}: {
  title: string;
  subscription: TWebPushPermissionData;
  notificationOptions?: TNotificationOptions;
}) => {
  /**
   * This check is necessary because we have seen some logs indicating that errors are being
   * thrown due to permissions not being granted when call showNotification. It's unclear how this
   * is happening, as users should only receive subscription data if they have granted permission.
   * If they manually change the permission, the subscription may expire automatically. We are not
   * addressing this error directly; instead, we are choosing to ignore it and halt further
   * execution.
   */
  if (Notification.permission !== 'granted') {
    return;
  }

  // When notification tag is welcome_notification.
  if (notificationOptions?.tag && notificationOptions.tag.includes(defaultNotificationTag)) {
    return (self as unknown as ServiceWorkerGlobalScope).registration.showNotification(
      title,
      notificationOptions,
    );
  }

  /**
   * We display the notification promptly to prevent subscription cancellations, as some browsers
   * automatically unsubscribe after a certain delay period.
   */
  await (self as unknown as ServiceWorkerGlobalScope).registration.showNotification(
    title,
    notificationOptions,
  );

  /**
   * If the notification options include a viewUrl This functionality is used
   * for advertising notifications.
   */
  if (notificationOptions?.viewUrl && notificationOptions?.tag) {
    try {
      await sendNotificationViewedReferAnalytics({
        tag: notificationOptions?.data?.originalTag || notificationOptions.tag,
      });

      log.info('Response from view refer analytics');
    } catch (error: any) {
      log.info(`Failed response from view refer analytics, ${error.name}`);
    }
  }

  // Send the view analytics to the server.
  if (notificationOptions?.tag) {
    try {
      await handleNotificationView({ notificationOptions, subscription });
    } catch (error: any) {
      log.info(`Failed to handle notification view, ${error.name}`);
    }
  }
};

export const showDefaultNotification = async ({
  subscription,
}: {
  subscription: TWebPushPermissionData;
}) => {
  const appId = await getAppId();

  const defaultNotificationPayload = {
    subscription,
    notificationOptions: {
      data: { url: '/', originalTag: defaultNotificationTag },
      tag: defaultNotificationTag,
      requireInteraction: false,
    },
    title: defaultNotificationTitle,
  };

  /**
   * If the appId is not present in the service worker URL, the default
   * notification will be displayed. It's possible that some customers
   * register the service worker themselves, and in such cases, the appId may
   * not be present in the service worker URL.
   */
  if (!appId) {
    log.debug('AppId is not present in the service worker URL.');

    await showNotification(defaultNotificationPayload);

    return;
  }

  // Retrieve the app data
  let appData: TServiceWorkerAppData | null = null;

  try {
    appData = await getAppData<TServiceWorkerAppData>({
      source: 'service-worker',
      appId: appId,
    });
  } catch (error: any) {
    // If the site is not found/deleted, it returns an error.
    log.info(`${error.message}`);

    await showNotification(defaultNotificationPayload);

    return;
  }

  if (!appData) {
    await showNotification(defaultNotificationPayload);

    throw new AppException({
      name: 'AppDataEmpty',
      message: 'App data is not available.',
      details: { appId, worker: self.location.href },
    });
  }

  const defaultNotification = appData.siteSettings.default_notification;

  if (!defaultNotification) {
    await showNotification(defaultNotificationPayload);

    throw new AppException({
      name: 'DefaultNotificationSettingNotFound',
      message: 'Default notification setting is not available.',
      details: { appId, worker: self.location.href },
    });
  }

  const site = appData.site;

  const notificationOptions = {
    body: defaultNotification.default_notification_message,
    icon: site.site_image,
    tag: `${defaultNotificationTag}${site.site_id}`,
    data: {
      url: defaultNotification.default_notification_url,
      originalTag: defaultNotificationTag,
    },
    requireInteraction: false,
  };

  await showNotification({
    subscription,
    notificationOptions,
    title: defaultNotification.default_notification_title,
  });
};

export const handleNotificationClick = async ({
  notificationOptions,
  userAction,
  subscription,
}: {
  notificationOptions: TNotificationOptions;
  userAction: string;
  subscription: TWebPushPermissionData;
}) => {
  /**
   * The user_visible_auto_notification tag is generated by the Chrome browser itself when a tag
   * is not provided in the notification options. However, in the case of Firefox and Safari,
   * when a tag is not passed in the notification options, they provide an empty string as the
   * tag. In these cases, it is not possible to track analytics for this notification.
   */
  if (
    !notificationOptions.tag ||
    notificationOptions.tag.includes(defaultNotificationTag) ||
    notificationOptions.tag === 'user_visible_auto_notification'
  ) {
    return;
  }

  const tag = notificationOptions.data?.originalTag || notificationOptions.tag;

  const isAlreadyNotificationClicked = await checkAndUpdateNotificationInDbByKey(
    tag,
    'clickedNotificationTags',
  );

  if (isAlreadyNotificationClicked) {
    return;
  }

  try {
    await sendNotificationClickedAnalytics({
      tag,
      action: userAction,
    });

    log.info('Response from clicked analytics');
  } catch (error: any) {
    log.info(`Failed response from clicked analytics, ${error.name}`);

    // Other errors are processed by the server through error logging
    if (navigator.onLine === false) {
      // No need to throw error in this case
      try {
        await addFailedClickedNotificationTag({
          tag,
          action: userAction,
        });
      } catch (error: any) {
        log.error(`Failed to add failed clicked notification tag to db, ${error.message}`);
      }
    } else {
      // This error is handled by the backend, so the payload should be added.
      sendErrorLog('service-worker', error, {
        tag,
        name: 'clickCountTrackingFailed',
        endpoint: subscription.endpoint,
        action: userAction,
        data: {
          tag,
          action: userAction,
        },
      });
    }
  }
};

export const handleNotificationOpenUrl = async (notificationUrl: string) => {
  const clientList = await (self as unknown as ServiceWorkerGlobalScope).clients.matchAll({
    type: 'window',
  });

  for (let i = 0; i < clientList.length; i++) {
    const client = clientList[i];

    if (client.url === notificationUrl && 'focus' in client) {
      return client.focus();
    }
  }

  try {
    const response = await (self as unknown as ServiceWorkerGlobalScope).clients.openWindow(
      notificationUrl,
    );

    return response;
  } catch (error) {
    /**
     * Throwing a custom error here because I want to exclude it when sending
     * logs to the server. This error is both frequent and non-critical, and
     * there's no solution to fix it.
     */
    throw new AppException({
      message: `Failed to open the URL '${notificationUrl}'.`,
      name: 'OpenNotificationUrlFailed',
      details: { url: notificationUrl },
    });
  }
};

/**
 * This function is used in AMP subscriptions to check the current subscription
 * state and broadcasts a single boolean value (true/false) that describes
 * whether the user is subscribed.
 */
export const handleMessageSubscriptionState = async (): Promise<void> => {
  const subscription = await (
    self as unknown as ServiceWorkerGlobalScope
  ).registration.pushManager.getSubscription();

  if (!subscription) {
    await broadcastReply(workerMessengerCommand.ampSubscriptionState, false);

    return;
  }

  const permission = await (
    self as unknown as ServiceWorkerGlobalScope
  ).registration.pushManager.permissionState(subscription.options);

  await broadcastReply(workerMessengerCommand.ampSubscriptionState, permission === 'granted');
};

/**
 * This function is used in AMP subscriptions to subscribe the visitor to push
 * notifications. In this case, the broadcasts value is null and not utilized
 * within the AMP page.
 */
export const handleMessageSubscribeState = async (): Promise<void> => {
  const appId = await getAppId();

  if (!appId) {
    throw new AppException({
      name: 'AppIdNotFound',
      message: 'AppId is not present in the service worker URL',
      details: { appId, worker: self.location.href },
    });
  }

  // Retrieve the app data
  let appData;

  try {
    appData = await getAppData<TServiceWorkerAmpAppData>({ appId, source: 'service-worker-amp' });
  } catch (error: any) {
    // If the site is not found/deleted, it returns an error.
    throw new AppException({
      name: 'InvalidAppId',
      message: error.message,
      details: { appId, worker: self.location.href },
    });
  }

  if (!appData) {
    throw new AppException({
      name: 'AppDataEmpty',
      message: 'App data is not available.',
      details: { appId, worker: self.location.href },
    });
  }

  const {
    gcm_options: gcmSetting,
    privacy_settings: privacySetting,
    vapid_key: vapidSetting,
  } = appData.siteSettings;
  const siteId = appData.site.site_id;

  if (!vapidSetting) {
    throw new AppException({
      name: 'VAPIDSettingNotFound',
      message: 'VAPID setting is not available.',
      details: { appId, siteId },
    });
  }

  if (!gcmSetting) {
    throw new AppException({
      name: 'GCMSettingNotFound',
      message: 'GCM setting is not available.',
      details: { appId, siteId },
    });
  }

  // Get the current subscription.
  let subscription;

  try {
    subscription = await subscribeToPushNotificationFromWorker(vapidSetting.public_key);
  } catch (error: any) {
    throw new AppException({
      name: 'AMPSubscribeFailed',
      message: `Failed to subscribe to push notifications, ${error.message}`,
      details: { appId, siteId },
    });
  }

  // Get the parent URL.
  let parentUrl = '';

  try {
    parentUrl = await getFirstClientUrl();
  } catch (error: any) {
    noop();
  }

  // Get the geo info.
  let geoInfo;

  if (privacySetting.geoLocationEnabled) {
    geoInfo = await getGeoInfo({ isEu: appData.site.is_eu });
  }

  // Format the payload.
  const browserInfo = getBrowserInfo('worker');

  const subscriberPayload = {
    browserInfo: {
      ...browserInfo,
      href: parentUrl,
    },
    site: appData.site,
    subscription: {
      ...subscription,
      project_id: gcmSetting.project_id,
      vapid_public_key: vapidSetting.public_key,
    },
    options: {
      geoInfo,
      optInType: OPT_IN_TYPES.singleStep,
    },
  };

  // Add the subscriber data to the site.
  try {
    await addSubscriberDataToSite(subscriberPayload);

    log.debug('Subscription added.');

    await broadcastReply(workerMessengerCommand.ampSubscribe, null);
  } catch (error: any) {
    throw new AppException({
      name: 'AddSubscriptionFailed',
      message: `Failed to adding subscription, ${error.message}`,
      details: { data: subscriberPayload },
    });
  }
};

export const handleMessageUnsubscribeState = async (): Promise<void> => {
  const subscription = await (
    self as unknown as ServiceWorkerGlobalScope
  ).registration.pushManager.getSubscription();

  if (!subscription) {
    return;
  }

  try {
    await subscription.unsubscribe();

    log.debug('Subscription unsubscribed.');

    await broadcastReply(workerMessengerCommand.ampUnsubscribe, null);
  } catch (error: any) {
    throw new AppException({
      message: `Failed unsubscribe to push notifications, ${error.message}.`,
      name: 'UnsubscribedFailedFromWorker',
      details: { worker: self.location.href },
    });
  }
};
